import React, { useState, useEffect } from "react"
import Amplify, { Auth } from "aws-amplify"
import isEqual from "lodash/isEqual"
import { navigate } from "gatsby"

import {
  USER_INFO_KEY,
  NEW_SUBSCRIPTION_KEY,
  redirectTo,
  isBrowser,
  getLocalStorage,
  getCurrentUser,
  getCreateAccountRoute,
  checkCurrentUser as checkCurrentUserUtil,
} from "../utils/utilities"

import {
  GetVimeoUserTicket,
  GetUserWithoutPayment,
  GetUser,
} from "../graphql/getUser"
import { ROUTES } from "../constants/routes"
import { cognitoConfig } from "../configs/cognito-config"
import type { PrivateRouteProps } from "../constants/types"

import LoadingIndicator from "../components/LoadingIndicator"
import { withCheckEnableCountries } from "../components/CheckEnableCountriesHoc/index.js"

const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    lang,
    redirectURL,
    loggedInRedirectURL,
    needVimeoUserTicket,
    component: Component,
    userQuery,
    ...rest
  } = props
  const currentUser = getLocalStorage(USER_INFO_KEY) || {} // Fix re-render header when navigate

  const [error, setError] = useState({})
  const [user, setUser] = useState(currentUser)
  const [checkingSession, setCheckingSession] = useState(true)
  const pathname = isBrowser ? window.location.pathname : ""
  const getUserQuery = userQuery
    ? userQuery
    : pathname.includes(ROUTES().CUSTOMER_ACCOUNT) ||
      pathname.includes(ROUTES().CHANGE_SUBSCRIPTION) ||
      pathname.includes(ROUTES().UPDATE_PAYMENT_DETAILS) ||
      pathname.includes(ROUTES().CREATE_ACCOUNT)
    ? GetUser
    : GetUserWithoutPayment

  const checkCurrentUser = userData => {
    setUser(userData)
    setCheckingSession(false)
    if (needVimeoUserTicket && userData) {
      redirectTo(
        loggedInRedirectURL,
        userData.userEntitlements?.LMOD?.vimeoUserTicket
      )
      return
    }

    checkCurrentUserUtil(
      userData,
      // withoutSubCallback
      // user has lmodSubscription is null
      () => {
        const { product_handle, offerId } = getLocalStorage(
          NEW_SUBSCRIPTION_KEY
        )

        // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/ENG-11
        // If there is no product handle, we will navigate directly to check-email
        // Instead of step 2
        if (!product_handle) {
          navigate(ROUTES(lang).CHECK_EMAIL)
          return
        }

        // Navigate to step 2 if user has no subscription
        navigate(`${getCreateAccountRoute(!!offerId, lang)}?step=payment`)
      },
      // withSubCallback
      () => {
        // keep user on my-account if user has subs (include cancelled sub)
        return
      }
    )
  }

  const handleRedirection = () => {
    // Redirect to Check Email page
    // https://lesmillsinternational.atlassian.net/browse/LA-735
    if (!needVimeoUserTicket) {
      getCurrentUser(
        checkCurrentUser,
        undefined,
        undefined,
        undefined,
        undefined,
        getUserQuery
      )
      return
    }

    // after login - redirect to loggedInRedirectURL
    // needVimeoUserTicket: is used for adding ticket param to URL after logged in
    if (needVimeoUserTicket) {
      getCurrentUser(
        checkCurrentUser,
        undefined,
        undefined,
        undefined,
        undefined,
        GetVimeoUserTicket
      )
    } else {
      redirectTo(loggedInRedirectURL)
    }
  }

  const getCurrentSession = async () => {
    const onError = async err => {
      // Make sure to clear all auth keys otherwise we end up with a redirection loop
      await Auth.signOut()
      setCheckingSession(false)
      setError(err)
      navigate(`${ROUTES(lang).SIGN_IN}?redirect_to=${pathname.slice(1)}`, {
        state: { fromPrivate: true, prevPath: pathname },
      })
    }

    Amplify.configure(cognitoConfig)

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()

      // Refresh token in order to make sure we always have the most recent information
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err, session) => {
          if (err) {
            onError(err)
            return
          }

          isBrowser &&
            localStorage.setItem("jwtToken", session?.idToken?.jwtToken)

          if (redirectURL) {
            handleRedirection()
          } else {
            getCurrentUser(
              userData => checkCurrentUser(userData),
              undefined,
              undefined,
              undefined,
              undefined,
              getUserQuery
            )
          }
        }
      )
    } catch (err) {
      onError(err)
    }
  }

  useEffect(() => {
    getCurrentSession()
  }, [isBrowser && !document.referrer])

  const noOnSignInPage = !pathname.includes(ROUTES().SIGN_IN)

  // Redirect to sign in if not authenticated
  if (Object.keys(error).length > 0 && noOnSignInPage) {
    navigate(`${ROUTES(lang).SIGN_IN}?redirect_to=${pathname.slice(1)}`, {
      state: {
        fromPrivate: true,
        prevPath: pathname,
      },
    })
    return null
  }

  // Show loading
  if (checkingSession) {
    return <LoadingIndicator isDarkTheme />
  }

  return <Component {...rest} user={user} checkingSession={checkingSession} />
}

export default React.memo(
  withCheckEnableCountries(PrivateRoute),
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.pageContext, nextProps.pageContext) &&
      isEqual(prevProps.location, nextProps.location) &&
      prevProps.lang === nextProps.lang &&
      prevProps.redirectURL === nextProps.redirectURL &&
      prevProps.needVimeoUserTicket === nextProps.needVimeoUserTicket
    )
  }
)
